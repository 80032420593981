import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivationHistoryService {

  constructor(private http: HttpClient) { }

  get(param?: any): Promise<any> {
    var url = environment.config.endPoint + "activationhistory";
    param = Object.assign({}, param);
    return new Promise((resolve, reject) => {
      this.http.get(url, { params: param, withCredentials: true })
        .toPromise()
        .then(resp => {
          resolve(resp);
        }).catch(error => {
          reject(error.error);
        });
    });
  }
}
