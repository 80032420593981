import { Component, OnInit, ViewChild } from '@angular/core';
import { LicenseService } from '../service/license.service'
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {
  licenses: Promise<Array<any>>;
  licenseDataSource: any;

  @ViewChild(MatSort) sortContaner;
  @ViewChild(MatPaginator) paginator;
  displayedColumns: string[] = ['id', 'serialNumber', 'created', 'expirationDate', 'isTrial', 'email', 'name', 'organization', 'productID', 'endUserCount', 'price'];
  constructor(private licenseService: LicenseService) { }

  ngOnInit() {
    this.licenseService.get().then(items => {
      this.licenseDataSource = new MatTableDataSource<any>(items);
      this.licenseDataSource.sort = this.sortContaner;
      this.licenseDataSource.paginator = this.paginator;
    });
  }

}