import { HomeComponent } from './home/home.component'
import { AdalGuard } from 'adal-angular4';
import { Routes, RouterModule } from '@angular/router';
import { LicenseComponent } from './license/license.component'
import { ProductComponent } from './product/product.component'
import { ActivationHistoryComponent } from './activation-history/activation-history.component'

export const ROUTES: Routes = [
    { path: '', component: ProductComponent, canActivate: [AdalGuard] },
    { path: 'products', component: ProductComponent, canActivate: [AdalGuard] },
    { path: 'licenses', component: LicenseComponent, canActivate: [AdalGuard] },
    { path: 'activationHistories', component: ActivationHistoryComponent, canActivate: [AdalGuard] }
]