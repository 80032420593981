import { Component, OnInit } from '@angular/core';
import {ProductService} from "../service/product.service"
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  products:Promise<Array<any>>;
  constructor(private productService: ProductService) { }
  
  ngOnInit() {
    this.products = this.productService.get();
  }

}
