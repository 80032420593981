import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AdalService, AdalGuard, AdalInterceptor } from 'adal-angular4';
import { ROUTES } from './app.routes'
import { HomeComponent } from './home/home.component';
import { DemoMaterialModule } from '../material-module';
import { LicenseComponent } from './license/license.component';
import { ProductComponent } from './product/product.component';
import { ActivationHistoryComponent } from './activation-history/activation-history.component';
import { ProductVersionComponent } from './product-version/product-version.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LicenseComponent,
    ProductComponent,
    ActivationHistoryComponent,
    ProductVersionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    RouterModule.forRoot(ROUTES, { useHash: true }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdalInterceptor,
      multi: true
    },
    AdalService, AdalGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
