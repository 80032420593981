import { Component, OnInit, ViewChild } from '@angular/core';
import {  ActivationHistoryService } from '../service/activation-history.service'
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';

@Component({
  selector: 'app-activation-history',
  templateUrl: './activation-history.component.html',
  styleUrls: ['./activation-history.component.css']
})
export class ActivationHistoryComponent implements OnInit {
  activationHistoryDataSource: any;

  @ViewChild(MatSort) sortContaner;
  @ViewChild(MatPaginator) paginator;
  displayedColumns: string[] = ['id','serialNumber', 'licenseID', 'featureID', 'machineCode', 'activateTime', 'isSuccess'];
  constructor(private activationHistoryService: ActivationHistoryService) { }

  ngOnInit() {
    this.activationHistoryService.get().then(items => {
      this.activationHistoryDataSource = new MatTableDataSource<any>(items);
      this.activationHistoryDataSource.sort = this.sortContaner;
      this.activationHistoryDataSource.paginator = this.paginator;
    });
  }

}
