import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from '../service/product.service'
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  licenses: Promise<Array<any>>;
  productDataSource: any;

  @ViewChild(MatSort) sortContaner;
  @ViewChild(MatPaginator) paginator;
  displayedColumns: string[] = ['id', 'productid', 'name', 'title', 'description', 'created', 'featureID', 'versionNumber'];
  constructor(private productService: ProductService) { }

  ngOnInit() {
    this.productService.get().then(items => {
      this.productDataSource = new MatTableDataSource<any>(items);
      this.productDataSource.sort = this.sortContaner;
      this.productDataSource.paginator = this.paginator;
    });
  }
}
