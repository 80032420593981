import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from '../environments/environment';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Modern SharePoint Solutions';
  fillerNav = [{
    title: "Products",
    route: "/products"
  },
  {
    title: "Licenses",
    route: "/licenses"
  },
  {
    title: "Activation History",
    route: "/activationHistories"
  }];
  constructor(private adalService: AdalService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    adalService.init(environment.config);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  mobileQuery: MediaQueryList;


  private _mobileQueryListener: () => void;
  ngOnInit() {
    this.adalService.handleWindowCallback();
    if (!this.authenticated) {
      this.adalService.login();
    }
  }

  get authenticated(): boolean {
    return this.adalService.userInfo.authenticated;
  }
}
